<template>
	<div>
		<div class="fcj_lr">
			<div>
				<div class="breadcrumb">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item><div class="pagetitle">合同管理</div></el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
			<div style="position: relative;">
				<el-button type="primary" @click="gourl('/contractform')" size="small" icon="el-icon-plus" style="position: absolute;right:20px;top:-30px;">添加合同</el-button>
			</div>
		</div>
		
			
		


		<div class="panel">
			<div class="panel-body pad16">
				<div class="panel-body" style="border-bottom: 1px solid #EBEEF5;">
					<div class="fcj_lr table-filter">

						<div class="fcj_ll">
							<el-input placeholder="请输入内容" size="small" v-model="keywords"
								class="input-with-select" @keyup.enter.native="getdata" style="width:260px;">
								<el-select v-model="typevalue" slot="prepend" placeholder="请选择" style="width:100px;">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
								<el-button slot="append" icon="el-icon-search" @click="getdata"></el-button>
							</el-input>
							<el-tooltip class="item" effect="dark" :content="searchproperty" placement="top-start">
								<property v-model="chproperty" :fsize="['small']" mul="1" @change="ch" style="margin:0 10px;">
								</property>
							</el-tooltip>


							<div>
								<div class="fcj_ll">
									<div style="font-size:13px;">到期日范围</div>
									<div>
										<el-date-picker v-model="searchkey.day" size="small" type="daterange" range-separator="至"
											start-placeholder="开始日期" end-placeholder="结束日期" style="width:270px;">
										</el-date-picker>
									</div>

								</div>
							</div>
							
							<el-select v-model="uid" slot="prepend" size="small" clearable placeholder="经办人"
								@change="getdata" class="searchitem">
								<el-option v-for="item in staff" :key="item.uid" :label="item.tname"
									:value="item.uid">
								</el-option>
							</el-select>
							

							<el-select v-model="statusvalue" slot="prepend" size="small" clearable placeholder="合同状态"
								@change="getdata" class="searchitem">
								<el-option v-for="item in status" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							<el-select v-model="searchkey.ctype" slot="prepend" size="small" clearable
								placeholder="合同类型" @change="getdata" class="searchitem">
								<el-option v-for="item in ctype" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>

							<el-date-picker
								v-model="searchkey.year"
								size="small"
								type="year"
								style="width:100px;"
								placeholder="统计年">
							</el-date-picker>
							<el-button size="small" type="primary" icon="el-icon-search" @click="getdata">查询</el-button>
							<el-button size="small" type="success" icon="el-icon-takeaway-box" @click="exportexcel">导出</el-button>
							
						</div>
						<div style="margin-left:10px;">
							
							
						</div>
					</div>
					<div class="fcj_lc table-filter">
						
						
						
						
					</div>
				</div>
				<div>
					<el-table v-loading="isLoading" :data="tableData" style="width:100%" header-cell-class-name="table-header" @sort-change="fcj_sort" stripe>

						<el-table-column label="合同编号" prop="number" width="250">

							<template slot-scope="scope">
								<router-link rel="stylesheet" type="primary"
									:to="'contractinfo?id='+scope.row.id">{{scope.row.number}}
								</router-link>
							</template>

						</el-table-column>
						<el-table-column prop="company" label="客户名称" width="300">
							<template slot-scope="scope">
								<router-link rel="stylesheet" type="primary"
									:to="'editcustomer?proview=1&id='+scope.row.customerid">{{scope.row.company}}
								</router-link>
							</template>
						</el-table-column>
						<el-table-column prop="office" label="租赁物业" width="200">
							<template slot-scope="scope">
								<div class="property" :title="scope.row.office">{{scope.row.office+((scope.row.office!=''&&scope.row.address!='')?'，':'')+scope.row.address}}</div>
							</template>
						</el-table-column>
						<el-table-column label="审批状态" width="90" prop="approval_state" :sortable="true">
							<template slot-scope="scope">
								<el-tag size="small" style="margin-right: 10px;" :type="approvalbtn[scope.row.approval_state]"
									disable-transitions>
									{{scope.row.approval_state==-1?'已撤销':scope.row.approval_state_name}}
								</el-tag>
						
							</template>
						</el-table-column>
						<el-table-column label="合同状态" width="90">
							<template slot-scope="scope">
								<el-tag size="small" style="margin-right: 10px;" :type="statebtn[scope.row.state]"
									disable-transitions>
									{{scope.row.statename}}
								</el-tag>
						
							</template>
						</el-table-column>
						<el-table-column prop="ctype" label="合同类型" width="120" :sortable="true">
							<template slot-scope="scope">
								{{ctypemap[scope.row.ctype]?ctypemap[scope.row.ctype].name:''}}
							</template>
						</el-table-column>
						<el-table-column label="面积(㎡)" prop="area" width="80" align="right">
						</el-table-column>
						<el-table-column label="单价(元)" prop="price" width="80" align="right">
						</el-table-column>
						<el-table-column label="月租金(元)" prop="money" width="100" align="right">
						</el-table-column>
						<el-table-column label="年租金(元)" prop="yearmoney" width="100" align="right">
						</el-table-column>
						<el-table-column label="租金押金(元)" prop="deposit" width="100" align="right">
						</el-table-column>
						<el-table-column label="物业单价(元/㎡/天)" prop="property" width="150" align="right">
						</el-table-column>
						<el-table-column label="月物业费(元)" prop="propertyfee" width="100" align="right">
						</el-table-column>
						<el-table-column label="年物业费(元)" prop="yearproperty" width="100" align="right">
						</el-table-column>
						<el-table-column label="物业押金(元)" prop="propertydeposit" width="100" align="right">
						</el-table-column>
						<el-table-column label="免租期" width="180" >
							<template slot-scope="scope">
								<div v-if="scope.row.freestart">{{scope.row.freestart+'~'+scope.row.freeterm}}</div>
							</template>
						</el-table-column>
						
						<el-table-column label="合同期限" width="180">
							<template slot-scope="scope">
								<div>{{scope.row.regist_time+'~'+scope.row.term}}</div>
							</template>
						</el-table-column>
						<el-table-column label="收款方式" width="100" >
							<template slot-scope="scope">
								<div>{{'付'+scope.row.cycle+'押'+scope.row.depositmonth}}</div>
							</template>
						</el-table-column>
						<el-table-column label="递增明细" width="160" >
							<template slot-scope="scope">
								<div v-for="iitem in scope.row.dizeng" :key="iitem.id">{{iitem.date+' '+iitem.deposit}}</div>
							</template>
						</el-table-column>
						
						<el-table-column label="递增日期" width="100" >
							<template slot-scope="scope">
								<div v-for="iitem in scope.row.dizeng" :key="iitem.id">{{iitem.date}}</div>
							</template>
						</el-table-column>
						
						<el-table-column label="合同到期日"  prop="term" width="100" >
						</el-table-column>
						<el-table-column label="实际退租期日"  prop="backtime" width="120" >
						</el-table-column>
							
						<el-table-column label="收款银行"  prop="bankinfo" width="200" >
						</el-table-column>
						<el-table-column label="空调配备与否"  prop="kongtiao" width="120" >
							<template slot-scope="scope">
								{{scope.row.kongtiao?'有':'无'}}
							</template>
						</el-table-column>
						<el-table-column label="业务员"  prop="personal" width="100" >
						</el-table-column>
						<el-table-column label="是否有中介"  prop="personal" width="100" >
							<template slot-scope="scope">
								{{scope.row.agency>0?'有':''}}
							</template>
						</el-table-column>
						<el-table-column label="中介费"  prop="agency" width="100" >
							<template slot-scope="scope">
								{{scope.row.agency>0?scope.row.agency:''}}
							</template>
						</el-table-column>
						<el-table-column label="注册地址"  prop="caddress" width="180" >
						</el-table-column>
						<el-table-column label="一级目录"  prop="trade1" width="100" >
						</el-table-column>
						<el-table-column label="二级目录"  prop="trade2" width="100" >
						</el-table-column>
						<el-table-column :label="searchyear+'年度收款金额租金'"  prop="receivedrent" width="120" >
						</el-table-column>
						<el-table-column :label="searchyear+'年度收款金额物业'"  prop="receivedproperty" width="120" >
						</el-table-column>
						
						<template v-for="iitem in [0,1,2,3,4,5,6,7,8,9,10,11]">
							<el-table-column :label="(iitem+1)+'月租金'" width="120" :key="'r'+iitem">
								<template slot-scope="scope">
									{{scope.row['monthrent'+iitem]}}
								</template>
							</el-table-column>
							<el-table-column :label="(iitem+1)+'月物业'" width="120" :key="'p'+iitem">
								<template slot-scope="scope">
									{{scope.row['monthproperty'+iitem]}}
								</template>
							</el-table-column>
						</template>
						
						
						
						
						<el-table-column prop="shouldrent" label="本年应收租金" width="120">
						</el-table-column>
						<el-table-column prop="shouldproperty" label="本年应收物业" width="120">
						</el-table-column>
						<el-table-column prop="shoulddeposit" label="本年应收租赁押金" width="140">
						</el-table-column>
						<el-table-column prop="shouldpropertydeposit" label="本年应收物业押金" width="140">
						</el-table-column>
						<el-table-column prop="should" label="合计" width="120">
						</el-table-column>
						
						
						
						
						
						<!-- <el-table-column prop="tj_amount" label="合同总租金" width="120">
						</el-table-column>
						<el-table-column prop="tj_amounts" label="实收租金" width="120">
						</el-table-column>
						<el-table-column prop="tj_property" label="合同总物业费" width="120">
						</el-table-column>
						<el-table-column prop="tj_propertys" label="实收物业费" width="120">
						</el-table-column>
						<el-table-column prop="tj_rentdeposit" label="租金押金" width="120">
						</el-table-column>
						<el-table-column prop="tj_propertydeposit" label="物业押金" width="120">
						</el-table-column>
						<el-table-column prop="tj_otherdeposit" label="其他押金" width="120">
						</el-table-column>
						<el-table-column prop="tj_cash" label="合同总头寸" width="120">
						</el-table-column>
						-->

						<el-table-column prop="money" label="操作" width="120" fixed="right">
							<template slot-scope="scope">

								
									
									<el-dropdown @command="menuClick" trigger="click">
										<el-button size="mini">
											更多操作<i class="el-icon-arrow-down el-icon--right"></i>
										</el-button>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item :command="[1,scope.row]" :disabled="scope.row.state>1">合同修改</el-dropdown-item>
											<el-dropdown-item :command="[2,scope.row]" :disabled="scope.row.state>0">合同延期</el-dropdown-item>
											<el-dropdown-item :command="[3,scope.row]" :disabled="scope.row.state>0">合同退租</el-dropdown-item>
											<el-dropdown-item :command="[4,scope.row,scope.$index]" :disabled="scope.row.state!=1">合同作废</el-dropdown-item>
											<el-dropdown-item :command="[5,scope.row]">合同账单</el-dropdown-item>
											<el-dropdown-item :command="[6,scope.row]" :disabled="scope.row.state==2">合同续租</el-dropdown-item>
											<el-dropdown-item :command="[7,scope.row]" >坏账</el-dropdown-item>
											<el-dropdown-item :command="[8,scope.row]" >取消坏账</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									

								
							</template>
						</el-table-column>

						<template>
							<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
						</template>
					</el-table>
				</div>
				<div style="padding:15px;text-align: right;">
					<el-pagination background layout="total,prev, pager, next" :current-page.sync="pgno" :page-size="pgsize"
						@current-change="page" :total="pgcount">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="isrerent" :class="['editback']" @close="cback" width="800px">
			<div slot="title" style="color:#121212;font-size:16px;">合同退租</div>
			<div style="height: 620px;">
				<div style="padding:20px;background-color: #f5f5f5;border-radius: 8px;margin:-20px 0 20px 0;">
					<el-row>
						<el-col :span="12">
							<div>合同编号：{{accontract.number}}</div>
						</el-col>
						<el-col :span="12">
							<div>客户名称：{{accontract.company}}</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<div>租赁期限：{{accontract.regist_time+'~'+accontract.term}}</div>
						</el-col>
						<el-col :span="12">
							<div>租赁物业：{{accontract.office+((accontract.office!=''&&accontract.address!='')?'，':'')+accontract.address}}</div>
						</el-col>
					</el-row>
				</div>
				<el-form :model="backform" :rules="rules" ref="backform" label-width="160px">
					<el-form-item label="退租日期" prop="date">
						<el-date-picker type="date" placeholder="选择日期" v-model="backform.date" style="width: 150px;">
						</el-date-picker>
					</el-form-item>
					<el-row>
						<el-col :span="12">
							<el-form-item label="应补租金" prop="srent">
								<el-input v-model="backform.srent" @input="inputch"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="应退租金" prop="rrent">
								<el-input v-model="backform.rrent" @input="inputch"></el-input>
							</el-form-item>
						</el-col>
						
					</el-row>
					
					<el-row>
						<el-col :span="12">
							<el-form-item label="应补物业费" prop="sproperty">
								<el-input v-model="backform.sproperty" @input="inputch"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="应退租金押金" prop="rrentdeposit">
								<el-input v-model="backform.rrentdeposit" @input="inputch"></el-input>
							</el-form-item>
						</el-col>
						
					</el-row>
					
					
					<el-row>
						<el-col :span="12">
							<el-form-item label="应补电费" prop="sele">
								<el-input v-model="backform.sele" @input="inputch"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="应退物业费" prop="rproperty">
								<el-input v-model="backform.rproperty" @input="inputch"></el-input>
							</el-form-item>
						</el-col>
						
					</el-row>

					<el-row>
						<el-col :span="12">
							<el-form-item label="应补水费" prop="swater">
								<el-input v-model="backform.swater" @input="inputch"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="应退物业费押金" prop="rpropertydeposit">
								<el-input v-model="backform.rpropertydeposit" @input="inputch"></el-input>
							</el-form-item>
						</el-col>
						
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="其他应补" prop="sother">
								<el-input v-model="backform.sother" @input="inputch"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12"><el-form-item label="其他应退" prop="rother">
								<el-input v-model="backform.rother" @input="inputch"></el-input>
							</el-form-item>
							
						</el-col>
						
					</el-row>

					<el-row>
						<el-col :span="12">
							<el-form-item label="退租赔偿金" prop="pei">
								<el-input v-model="backform.pei" @input="inputch"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					
					<el-row>
						<el-col :span="12"><el-form-item label="差额(应补-应退)" prop="cha">
								<el-input v-model="backform.cha" readonly></el-input>
							</el-form-item>
							
						</el-col>
						
					</el-row>
					
					
					<el-form-item label="退租说明" prop="backnote">
						<el-input type="textarea" v-model="backform.backnote"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="rerent()">确认</el-button>
						<!-- <el-button type="warning" @click="ddrerent()">发起钉钉审批</el-button> -->

						<el-button @click="cback">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>


		
		<el-dialog :visible.sync="isyanqi" :class="['editback']" width="700px" @close="closeyanqi">
			<el-alert title="适合合同到期短期延租，延期后需手工创建一张延期账单" type="info" show-icon>
			</el-alert>
			<el-descriptions :column="1" size="medium">
				<el-descriptions-item label="合同编号" label-class-name="des-label" content-class-name="des-content">
					{{accontract.number}}
				</el-descriptions-item>
				<el-descriptions-item label="客户名称" label-class-name="des-label" content-class-name="des-content">
					{{accontract.company}}
				</el-descriptions-item>
				<el-descriptions-item label="租赁期限" label-class-name="des-label" content-class-name="des-content">
					{{accontract.regist_time+'~'+accontract.term}}
				</el-descriptions-item>
			</el-descriptions>

			<el-form :model="yanqiform" :rules="yanqirule" ref="yanqiform" label-width="160px">

				<el-form-item label="新结束日期" prop="term">
					<div class="fcj_ll">
						<div>{{accontract.newstart}}</div>
						<div style="padding:0 10px;">至</div>
						<div>
							<el-date-picker type="date" placeholder="结束日期" v-model="yanqiform.term" :default-value="accontract.newstart"
								style="width: 150px;" :picker-options="pickerOptions">
							</el-date-picker>
						</div>
					</div>

				</el-form-item>


				<el-form-item label="备注" prop="note">
					<el-input type="textarea" v-model="yanqiform.note"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="yanqi()">确认延期</el-button>
					<el-button @click="resetForm('yanqiform')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog :visible.sync="isshowddform" width="600px"  :close-on-click-modal="false">
			<form-create v-model="fApi" :rule="ddrule" :option="option" :value.sync="ddformdata">
			</form-create>
		</el-dialog>
	</div>
</template>

<script>
	import property from '../components/property.vue'
	export default {
		components: {
			property
		},
		data(vm) {
			return {
				isLoading:false,
				down:0,
				host:this.$host,
				pickerOptions: {
					disabledDate: (time) => {
						return time.getTime() < (new Date(this.accontract.newstart)).getTime();
					}
				},
				searchyear:'',
				searchkey: {
					day: [],
					ctype: '',
					property: '',
					year:'',
					thisyear:0,
					sign:0,
					sort:''
				},
				searchproperty: '空',
				chproperty: {},
				state: [],
				isyanqi: false,
				yanqiform: {
					do: 'yanqi',
					term: '',
					note: ''
				},
				yanqirule: {

				},
				propertytypeid: '',
				propertytype: ['办公室', '商铺', '地下室'],
				
				ctypemap: {},
				ctype: [],
				
				backform: {
					comname: '',
					backnote: "",
					date: "",
					do: "update",
					id: "",
					rother: 0,
					rproperty: 0,
					rpropertydeposit: 0,
					rrent: 0,
					rrentdeposit: 0,
					sele: 0,
					sother: 0,
					sproperty: 0,
					srent: 0,
					swater: 0,
					cha:0,
					pei:0,
				},
				rules: {
					date: [{
						required: true,
						message: '请选择退租日期',
						trigger: 'change'
					}],
					rrent: [{
						required: true,
						message: '请输入应退租金',
						trigger: 'blur'
					}],
					srent: [{
						required: true,
						message: '请输入应补租金',
						trigger: 'blur'
					}],
					rrentdeposit: [{
						required: true,
						message: '请输入应退租金押金',
						trigger: 'blur'
					}],
					sproperty: [{
						required: true,
						message: '请输入应补物业费',
						trigger: 'blur'
					}],
					rproperty: [{
						required: true,
						message: '请输入应退物业费',
						trigger: 'blur'
					}],
					sele: [{
						required: true,
						message: '请输入应补电费',
						trigger: 'blur'
					}],
					rpropertydeposit: [{
						required: true,
						message: '请输入应退物业费押金',
						trigger: 'blur'
					}],
					swater: [{
						required: true,
						message: '请输入应补水费',
						trigger: 'blur'
					}],
					rother: [{
						required: true,
						message: '请输入其他应退',
						trigger: 'blur'
					}],
					sother: [{
						required: true,
						message: '请输入其他应补',
						trigger: 'blur'
					}],
					pei: [{
						required: true,
						message: '请输入退租赔偿金',
						trigger: 'blur'
					}],
					cha: [{
						required: true,
						message: '请输入差额',
						trigger: 'blur'
					}]

				},
				
				accontract: {},
				isrerent: false,
				pgno: 1,
				pgcount: 0,
				pgsize: 13,
				keywords: '',
				approvalbtn: ['info', 'warning', 'danger', 'success'],
				statebtn: ['success', '', 'warning', 'info'],
				staff: [],
				options: [{
					value: '1',
					label: '客户名称'
				}, {
					value: '2',
					label: '合同编号'
				}],
				status: [{
					value: '0',
					label: '正常'
				}, {
					value: '1',
					label: '预签'
				}, {
					value: '2',
					label: '作废'
				}, {
					value: '3',
					label: '结束'
				}],

				typevalue: '1',
				statusvalue: '',
				sestaff: '',
				comtype: [],
				tableData: [],
				jobs: ['招商人员', '维修人员'],
				jobs2: ['招商人员', '物业人员', '财务人员', '管理层'],
				ruleForm: {
					uuid: '',
					name: '',
					tp: []
				},
				uid:'',
				uploaddata:{
					pusern:localStorage.getItem('pusern'),
					backapi:1,
					sys:localStorage.getItem('sys')
				},


				//实例对象
					fApi: {},
					//表单数据
					ddformdata: {},
					//表单生成规则
					ddrule: [],
					//组件参数配置
					option: {
						//表单提交事件
						onSubmit: function (formData) {
							vm.addddform();
						}
					},
				ddpost:{},
				isshowddform:false,
				sedduserdiv:[],
				seddccdiv:[],
				seddcc:[],//钉钉抄送人列表
				ddusertype:0,//当前是选审批人还是抄送人1，审批人，1抄送人
				ddformattdata:[],//钉钉表单附件暂存
				ddformattdataview:[],
				schema:[],
				sysset:{},
				havecontract:0,//1合同已保存成功
				acsys:{}
				
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=[to];
			})
		},
		activated(){
			var pg=this.$pageno({'page':'contract'});
			this.getdata({
				pgno: pg?pg:1
			});
		},
		mounted: function() {
			var pg=this.$pageno({'page':'contract'});
			var e='';
			var s='';
			if(this.$route.query.endtime){
				s=new Date();
				e=new Date();
				e.setDate(s.getDate()+30);
				this.statusvalue='0';
				this.searchkey.day=[s.getTime(),e.getTime()]
			}else if(this.$route.query.outtime){
				s=new Date();
				this.statusvalue='0';
				this.searchkey.day=['',s.getTime()-1]
			}
			if(this.$route.query.state){
				this.statusvalue=this.$route.query.state;
			}
			if(this.$route.query.thisyear){
				this.searchkey.thisyear=1;
				if(this.$route.query.sign){
					this.searchkey.sign=this.$route.query.sign;
				}
			}
			this.getdata({
				pgno: pg?pg:1
			});
			this.host = this.$host
		},
		methods: {
			fcj_sort(e){
				this.searchkey.sort=[e.prop,e.order];
				this.page(1);
			},
			addddform(){
				if(this.sedduser.length==0){
					this.$message({
						message: '请选择审批人',
						type: 'warning'
					})
					return
				}else{
					var uu=[]
					for(var i=0;i<this.sedduser.length;i++){
						uu.push(this.sedduser[i].userid);
					}
				}
				var cc=[];
				if(this.seddcc.length>0){
					for(i=0;i<this.seddcc.length;i++){
						cc.push(this.seddcc[i].userid);
					}
				}
				this.ddpost.dduser=uu;
				this.ddpost.cc=cc;

				var fd=[];
				for(i=0;i<this.schema.length;i++){
					fd[i]={"name":this.schema[i].props.label,"value":this.ddformdata[this.schema[i].props.id]?this.ddformdata[this.schema[i].props.id]:'',"tp":this.schema[i].componentName}
				}
				this.ddpost.formdata=fd;

				//this.submitForm('ruleForm')
			},
			ddrerent(){
				var smap={};
				this.dingding=1;
				this.isshowddform=true;

				for (var i = 0 ;i<this.schema.length ; i++) {
					if(this.schema[i].props.label=='房租押金'){
						this.schema[i].props.value=this.backform.comname
						continue;
					}
					
				}
				for (i=0;i<this.schema.length;i++){
					smap[this.schema[i].props.id]=this.schema[i].props.value?this.schema[i].props.value:''
				}
				for(i=0;i<this.ddrule.length;i++){
					this.ddrule[i].value=smap[this.ddrule[i].field]
				}
			},
			inidduid(){
				//通过手机号关联dd uid
				this.$ajax({
					url: 'ddapi/',
					data: {
						do: 'ddalluser'
					},
				}).then(res => {
					
				});
				// 取审批表单
				const that=this;
				this.$ajax({
					url: 'ddapi/',
					data: {
						do: 'getschema',
						modeid:'PROC-TA5LVCPV-KOHUMATPR4C3HFG31IMU1-ZS6KN7GJ-2'
					},
				}).then(res => {
					var tmp=res.result.schemaContent.items;
					this.schema=tmp;
					for (var i = tmp.length - 1; i >= 0; i--) {
						if(tmp[i].componentName=="TextField"||"NumberField"==tmp[i].componentName||"MoneyField"==tmp[i].componentName){
							this.ddrule.unshift({
								type: 'input',
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								props:{
									placeholder:tmp[i].props.placeholder
									
								},
								validate:[{
									required:tmp[i].props.required,
									message:'请输入'+tmp[i].props.label
								}]
							})
						}else if("DDSelectField"==tmp[i].componentName){
							for(var j=0;j<tmp[i].props.options.length;j++){
								tmp[i].props.options[j]=JSON.parse(tmp[i].props.options[j]);
								tmp[i].props.options[j].label=tmp[i].props.options[j].value;

							}
							this.ddrule.unshift({
								type: "select",
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								options: tmp[i].props.options,
								validate:[{
									required:tmp[i].props.required,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									multiple: false
								}
							})
						}else if("DDDateField"==tmp[i].componentName){
							
							this.ddrule.unshift({
								type: "DatePicker",
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								validate:[{
									required:tmp[i].props.required,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									format: tmp[i].props.format,
								}
							})
						}else if("NumberField"==tmp[i].componentName){
							this.ddrule.unshift({
								type: 'input',
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								props:{
									placeholder:tmp[i].props.placeholder
								},
								validate:[{
									required:tmp[i].props.required,
									message:'请输入'+tmp[i].props.label
								}]
							})
						}else if("DDDateRangeField"==tmp[i].componentName){
							tmp[i].props.label=JSON.parse(tmp[i].props.label);
							this.ddrule.unshift({
								type: "DatePicker",
								field: tmp[i].props.id,
								title: tmp[i].props.label[0]+','+tmp[i].props.label[1],
								validate:[{
									required:tmp[i].props.required,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									tpye:'daterange',
									format: tmp[i].props.format,

								}
							})
						}else if("TextareaField"==tmp[i].componentName){
							this.ddrule.unshift({
								type: 'input',
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								validate:[{
									required:tmp[i].props.required,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									rows:5,
									type: "textarea",
								},
							})
						}else if("DDAttachment"==tmp[i].componentName){
							var updata=JSON.parse(JSON.stringify(this.uploaddata));
							updata['noc']=tmp[i].props.id;

							this.ddrule.unshift({
								type: 'el-upload',
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								validate:[{
									required:tmp[i].props.required,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									action:this.host+'upfile/',
									name:"upfile",
									data: updata,
									onSuccess:function (res, file ,filelist) {
										console.log(file);
										console.log(filelist);
										console.log(that.value);
										that.ddformdata[res.noc]=[]
										for(var i=0;i<filelist.length;i++){
											that.ddformdata[res.noc].push(filelist[i].response.fileid)
										}
									}
								},
								children:[{
									type: 'el-button',
									children:['上传附件']
								}]
							})

						}
						
					}
					this.ddrule.push({
						type:'div',
						props:{
							class:'el-col el-col-24 fcj_ll',
							style:'padding-bottom:13px;'
						},
						children:[{
							type:'div',
							props:{
								style:'width:125px;'
							}
						},{
							type:'div',
							children:[
								{
									type:'el-button',
									children:['选择审批人'],
									on:{
										click:()=>{
											this.ddsp(0);
										}
									}
								},
								{
									type:'div',
									style:'padding:10px 0',
									children:this.sedduserdiv
								}
							]
						}],
						
					})
					this.ddrule.push({
						type:'div',
						props:{
							class:'el-col el-col-24 fcj_ll',
							style:'padding-bottom:13px;'
						},
						children:[{
							type:'div',
							props:{
								style:'width:125px;'
							}
						},{
							type:'div',
							children:[
								{
									type:'el-button',
									children:['选择抄送人'],
									on:{
										click:()=>{
											this.ddsp(1);
										}
									}
								},
								{
									type:'div',
									style:'padding:10px 0',
									children:this.seddccdiv
								}
							]
						}],
						
					})
				});
				
			},
			gourl(v){
				this.$router.push(v)
			},
			exportexcel(){
				this.down=1;
				this.getdata();
			},
			cback(){
				this.$refs['backform'].resetFields();
				this.isrerent=false
			},
			inputch(){
				var h=this.backform.srent.add(
				this.backform.sproperty).add(
				this.backform.sele).add(
				this.backform.swater).add(
				this.backform.sother).add(
				this.backform.pei)
				
				var c=this.backform.rrent.add(
				this.backform.rrentdeposit).add(
				this.backform.rproperty).add(
				this.backform.rpropertydeposit).add(
				this.backform.rother)
				
				this.backform.cha=h.accSub(c)
			},
			menuClick(v){
				if(v[0]==1){
					this.$router.push('contractform?id=' + v[1].id)
				}else if(v[0]==2){
					this.yanqi(v[1])
				}else if(v[0]==3){
					this.$router.push('bill?back=1&id=' + v[1].id)
					//this.rerent(v[1])
				}else if(v[0]==4){
					this.zuofei(v[2],v[1])
				}else if(v[0]==5){
					this.$pageno({'page':'bill','value':1})
					this.$router.push('bill?id=' + v[1].id)
				}else if(v[0]==6){
					this.$router.push('contractform?renewal=1&id=' + v[1].id)
				}else if(v[0]==7){
					this.setbad(v[2],v[1],1)
				}else if(v[0]==8){
					this.setbad(v[2],v[1],0)
				}
				
			},
			setbad(v,t,ch){
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'baddebts',
						contractid: t.id,
						ch:ch
					}
				}).then(res => {
					this.$alert('操作成功', '提示', {
						confirmButtonText: '确定'
					});
				})
			},
			zuofei(id, row) {
				this.$confirm('确定将该合同作废吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$ajax({
						url: 'contract/',

						data: {
							'do': 'update',
							'id': row.id,
							'st': 2
						},
					}).then(res => {

						if (res.error == 0) {
							this.accontract.term = res.data
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});
							this.tableData[id].state = 2
							this.tableData[id].statename = '作废'

						} else {
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});

						}
					})
					
				}).catch(() => {});
			},
			ch() {
				this.searchproperty = this.chproperty.propertyname;
				this.searchkey.prototype = {
					'buildch': this.chproperty.buildch,
					'floorch': this.chproperty.floorch,
					'roomch': this.chproperty.roomch
				}
				this.getdata({
					pgno: 1
				});
			},
			closeyanqi(){
				this.yanqiform.term=''
			},
			yanqi(row) {
				if (row) {

					var n = new Date(row.term);
					n = +n + 1000 * 3600 * 24;
					n = new Date(n);

					row.newstart = n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate()
					this.accontract = row
					//this.yanqiform.term = row.term;
					this.yanqiform.oldterm = row.term;
					this.yanqiform.company = row.company
					this.isyanqi = true;
				} else {
					this.$refs['yanqiform'].validate((valid) => {
						if (valid) {
							this.yanqiform.id = this.accontract.id;
							this.$ajax({
								url: 'contract/',
								data: this.yanqiform,
							}).then(res => {

								if (res.error == 0) {
									
									this.page(this.pgno);
									this.isyanqi=false
									this.$confirm('合同延期成功！如需额外收费，请手工创建延期账单。', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '创建账单',
										type: 'warning'
									}).then(() => {
										
									}).catch(() => {
										this.$router.push('bill?id=' + this.accontract.id)
									});

								} else {
									this.$alert(res.msg, '提示', {
										confirmButtonText: '确定'
									});

								}
							})
						}
					})
				}
			},


			rerent(row) {
				if (row) {
					this.accontract = row;
					this.isrerent = true
					this.inidduid();
				} else {
					if(this.backform.cha>0){
						this.$confirm('如需补录账单，请先手工创建账单并确认结算后再退租', '提示', {
							confirmButtonText: '直接退租',
							cancelButtonText: '补录账单',
							type: 'warning'
						}).then(() => {
							this.back();
						}).catch(() => {
							this.$router.push('bill?id='+this.accontract.id)
						});
					}else{
						this.back();
					}
					
				}
			},
			back(){
				this.$refs['backform'].validate((valid) => {
					if (valid) {
						this.backform.id = this.accontract.id
						this.$ajax({
							url: 'contract/',
							data: this.backform,
						}).then(res => {
				
							if (res.error ==0){
								this.isrerent=false;
								this.accontract.state = 3;
							}
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});
						})
					}
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$ajax({
							url: 'user/',
							data: {
								'do': 'add',
								'uuid': this.ruleForm.uuid,
								'addname': this.ruleForm.name,
								'tp': this.ruleForm.tp
							},
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.resetForm('ruleForm');
								this.$message({
									message: '添加成功',
									type: 'success'
								});
								this.getdata();

							}

						})
					} else {
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			page(v) {
				this.getdata({
					pgno: v
				});
			},
			getdata(v) {
				var pg=1;
				if (typeof(v) == 'object'&&v.pgno ) {
					pg=v.pgno;
				}
				this.isLoading=true;
				const that=this;
				this.$ajax({
					responseType:this.down?'blob':'',
					url: 'contract/',
					data: {
						'do': 'list',
						searchkey: this.searchkey,
						state: this.statusvalue,
						number: this.typevalue == 2 ? this.keywords : '',
						company: this.typevalue == 1 ? this.keywords : '',
						straff: this.sestaff,
						uid:this.uid,
						pgno: pg,
						pgsize: this.pgsize,
						down:this.down
					},
					onDownloadProgress:this.down?function(p){
						that.percent=Math.round(p.loaded/p.total*100);
						//console.log(Math.round(p.loaded/p.total*100))
					}:''
				}).then(res => {
					this.isLoading=false;
					if(this.down){
						this.down=0;
						let url=window.URL.createObjectURL(new Blob([res]));
						let link=document.createElement('a')
						link.download='合同表.xls'
						link.href=url
						link.click()
						return
					}
					
					this.ctype = res.ctype;
					for (var i = 0; i < this.ctype.length; i++) {
						this.ctypemap[this.ctype[i].id] = this.ctype[i];
					}
					this.searchyear=res.year;
					this.staff = res.staff;
					this.pgcount = parseInt(res.count);
					this.tableData = res.res;
					for(i=0;i<this.tableData.length;i++){
						for(var j=0;j<this.tableData[i].monthrent.length;j++){
							if(this.tableData[i].monthrent[j]){
								this.tableData[i]['monthrent'+j]=this.tableData[i].monthrent[j]
							}else{
								this.tableData[i]['monthrent'+j]=''
							}
							if(this.tableData[i].monthproperty[j]){
								this.tableData[i]['monthproperty'+j]=this.tableData[i].monthproperty[j]
							}else{
								this.tableData[i]['monthproperty'+j]=''
							}
						}
					}
					this.state = res.state
					this.comtype = res.comtype;
					this.pgno=pg;
					this.$pageno({'page':'contract','value':pg});
				})
			}

		}
	};
</script>

<style scoped>
	.searchitem {
		margin-right: 10px;
		width: 100px;
	}

	.el-select .el-input {
		width: 130px;
	}

	.editback .el-row {
		margin-bottom: 0px;
	}

	.input-with-select {
		width: 320px;
	}

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}

	.companyname {
		cursor: pointer;
		color: #409EFF;
	}

	.property {
		/* height: 46px;
		line-height: 23px; */
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		/* -webkit-line-clamp: 2; */
	}
</style>
