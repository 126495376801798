<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right" v-if="breadcrumb.length>0">
				<div class="pagetitle">{{breadcrumb[breadcrumb.length-1].name}}</div>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index"
					v-show="index!=breadcrumb.length-1">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row>
			<el-col>
				<div class="panel pad16">
					<div class="fcj_lr" style="margin:0 10px;">
						<div style="color: #FA6400;font-size:20px;">合同ID：{{res.id}}</div>
					</div>
					<el-row>
						<el-col :span="16">
							<div class="info res pad16" style="line-height:180%;font-size:12px;">
								<el-descriptions title="" :column="1" size="medium" v-if="res.id">
									<el-descriptions-item label="关联的旧合同" label-class-name="des-label"
										content-class-name="des-content" v-if="res.fromid>0">
										<router-link :to="'/contractinfo?id='+res.fromid">{{res.fromid}}</router-link>
									</el-descriptions-item>
									<el-descriptions-item label="合同编号" label-class-name="des-label"
										content-class-name="des-content">
										{{res.number}}
									</el-descriptions-item>
									<el-descriptions-item label="合同类型" label-class-name="des-label"
										content-class-name="des-content" v-if="!isshowini">
										{{contracttype[res.ctype].name}}

										<el-rate v-model="res.avgstar" disabled show-score text-color="#ff9900"
											score-template="">
										</el-rate>
									</el-descriptions-item>

									<el-descriptions-item label="租赁类型" label-class-name="des-label"
										content-class-name="des-content" v-if="!isshowini">
										<div class="tag-group">
											<el-tag v-for="(item,index) in rent_type" :key="index" v-show="item.fid==res.rent_type">
												{{item.class_name}}
											</el-tag>
										</div>
									</el-descriptions-item>

									<el-descriptions-item label="签署合同类型" label-class-name="des-label"
										content-class-name="des-content" v-if="!isshowini">
										<div class="tag-group">
											<el-tag v-for="(item,index) in createtype" :key="index" v-show="item.fid==res.createtype">
												{{item.class_name}}
											</el-tag>
										</div>
									</el-descriptions-item>

									

									<el-descriptions-item label="单位名称" label-class-name="des-label"
										content-class-name="des-content">
										{{res.company}}
									</el-descriptions-item>
									<template v-if="!isshowini">
										<el-descriptions-item label="单位联系人" label-class-name="des-label"
											content-class-name="des-content">
											{{res.connecter}}
										</el-descriptions-item>
										<el-descriptions-item label="联系电话" label-class-name="des-label"
											content-class-name="des-content">
											{{res.phone}}
										</el-descriptions-item>
										<el-descriptions-item label="所租物业" label-class-name="des-label"
											content-class-name="des-content">
											{{res.office}}
										</el-descriptions-item>
										<el-descriptions-item label="其他物业" label-class-name="des-label"
											content-class-name="des-content">
											{{res.address}}
										</el-descriptions-item>
									</template>
									<el-descriptions-item label="租赁期限" label-class-name="des-label"
										content-class-name="des-content">
										{{res.regist_time+' ~ '+res.term}}
									</el-descriptions-item>
									<el-descriptions-item label="免租期限" label-class-name="des-label"
										content-class-name="des-content">
										{{res.freestart+' ~ '+res.freeterm}}
									</el-descriptions-item>
									<el-descriptions-item label="收费项目" label-class-name="des-label"
										content-class-name="des-content">
										<div class="tag-group">
											<el-tag v-for="(item,index) in res.paytype" :key="index">
												{{paytype[item]?paytype[item].class_name:''}}
											</el-tag>
										</div>
									</el-descriptions-item>
									<el-descriptions-item label="租赁总面积" label-class-name="des-label"
										content-class-name="des-content">
										{{res.area/100}} m<sup>2</sup>
									</el-descriptions-item>
									<el-descriptions-item label="租金单价" label-class-name="des-label"
										content-class-name="des-content">
										{{res.price/100}} 元/m<sup>2</sup>/天
									</el-descriptions-item>
									<el-descriptions-item label="物业单价" label-class-name="des-label"
										content-class-name="des-content">
										{{res.property/100}} 元/天/m<sup>2</sup>
									</el-descriptions-item>

									<el-descriptions-item label="月租金" label-class-name="des-label"
										content-class-name="des-content">
										<div class="fcj_ll">
											<div style="width:100px;">{{res.money/100}} 元</div>
											<div style="width:100px;">付{{res.cycle}}押{{res.depositmonth}}</div>
											<div>租金押金：{{res.deposit/100}} 元</div>
										</div>

									</el-descriptions-item>
									<el-descriptions-item label="月物业费" label-class-name="des-label"
										content-class-name="des-content">
										<div class="fcj_ll">
											<div style="width:100px;">{{res.propertyfee/100}} 元</div>
											<div style="width:100px;">
												付{{res.propertycycle}}押{{res.propertydepositmonth}}</div>
											<div>物业费押金：{{res.propertydeposit/100}} 元</div>
										</div>
									</el-descriptions-item>


									<el-descriptions-item label="一次性租金" label-class-name="des-label"
										content-class-name="des-content">
										{{res.oncerent/100}} 元
									</el-descriptions-item>
									<el-descriptions-item label="其他押金" label-class-name="des-label"
										content-class-name="des-content">
										{{res.otherrent/100}} 元
									</el-descriptions-item>
									<!-- <el-descriptions-item label="首期租金" label-class-name="des-label"
										content-class-name="des-content">
										{{res.firstmoney/100}} 元
									</el-descriptions-item>
									<el-descriptions-item label="首期物业费" label-class-name="des-label"
										content-class-name="des-content">
										{{res.firstpropertyfee/100}} 元
									</el-descriptions-item> -->
									<el-descriptions-item label="首期账单周期" label-class-name="des-label"
										content-class-name="des-content">
										{{res.firstdate}}
									</el-descriptions-item>
									<el-descriptions-item label="首期物业费账单周期" label-class-name="des-label"
										content-class-name="des-content">
										{{res.firstpropertydate}}
									</el-descriptions-item>
									<el-descriptions-item label="租金递增" label-class-name="des-label"
										content-class-name="des-content">
										<div v-if="dizeng.length>0&&dizeng[0].deposit!=''">
											<div v-for="(item,index) in dizeng" :key="index">
												递增后月租金：{{item.deposit}}，开始生效日期：{{item.date}}</div>
										</div>
										<div v-else>没有递增</div>
									</el-descriptions-item>
									<template v-if="!isshowini">
										<el-descriptions-item label="空调数量" label-class-name="des-label"
											content-class-name="des-content">
											{{res.kongtiao}}
										</el-descriptions-item>
										<el-descriptions-item label="车位数量" label-class-name="des-label"
											content-class-name="des-content">
											{{res.car}}
										</el-descriptions-item>
										<el-descriptions-item label="经办业务员" label-class-name="des-label"
											content-class-name="des-content">
											{{res.personal}}
										</el-descriptions-item>
										<el-descriptions-item label="中介费用(元)" label-class-name="des-label"
											content-class-name="des-content">
											{{res.agency}} 元
										</el-descriptions-item>
										<el-descriptions-item label="租金收款账号" label-class-name="des-label"
											content-class-name="des-content">
											{{banks?banks[res.bankid].des:''}}
										</el-descriptions-item>
										<el-descriptions-item label="物业收款账号" label-class-name="des-label"
											content-class-name="des-content">
											{{banks?banks[res.propertybankid].des:''}}
										</el-descriptions-item>
										<el-descriptions-item label="补充条款" label-class-name="des-label"
											content-class-name="des-content">
											{{descdate.length>0?descdate[0].des:''}}
										</el-descriptions-item>
										<el-descriptions-item label="条款触发日期" label-class-name="des-label"
											content-class-name="des-content">
											<div class="tag-group">
												<el-tag v-for="(item,index) in descdate" :key="index">{{item.dt}}
												</el-tag>
											</div>
										</el-descriptions-item>
										<el-descriptions-item label="状态" label-class-name="des-label"
											content-class-name="des-content">
											{{states[res.state]}}
										</el-descriptions-item>
										<el-descriptions-item label="退租原因" label-class-name="des-label"
											content-class-name="des-content">
											{{res.backnote}}
										</el-descriptions-item>
										<el-descriptions-item label="操作员" label-class-name="des-label"
											content-class-name="des-content">
											{{res.name}}
										</el-descriptions-item>
										<el-descriptions-item label="合同录入时间" label-class-name="des-label"
											content-class-name="des-content">
											{{res.create_time}}
										</el-descriptions-item>
										<el-descriptions-item label="实际退租日期" label-class-name="des-label"
											content-class-name="des-content">
											{{res.backtime}}
										</el-descriptions-item>
										<el-descriptions-item label="审批状态" label-class-name="des-label"
											content-class-name="des-content">
											{{approval_state[res.approval_state]}}
										</el-descriptions-item>
										<el-descriptions-item label="合同备注" label-class-name="des-label"
											content-class-name="des-content">
											{{res.note}}
										</el-descriptions-item>
										<el-descriptions-item label="合同附件" label-class-name="des-label"
											content-class-name="des-content">
											<div>
												<div style="margin-bottom:10px;">
													<el-upload :action="host+'upfile/'" :data="uploaddata" list-type="text" ref="uploadparkpic" name="upfile" :on-success="uploadsuccess" :auto-upload="true" :limit="1">
															<el-button type="success" size="small" plain>上传附件</el-button>
															
														</el-upload>
												</div>

												<div>
												<div v-for="(item,index) in res.files"  :key="'att'+index"><a :href="host+item.fpath" target="_blank">{{item.fname}}</a></div>
												</div>
											
											</div>
										</el-descriptions-item>
										<el-descriptions-item label="合同绑定人员" label-class-name="des-label"
											content-class-name="des-content">
											<div>
											<div>
												<el-button @click="addmember" size="small" plain type="primary"
													style="margin-bottom: 10px;">添加人员</el-button>
											</div>
											<div class="fcj_ll" style="flex-wrap: wrap;" v-if="p.c>0">
												<div v-for="(items,iindex) in p.res" :key="items.id" class="fcj_ll"
													style="margin-right: 10px;margin-bottom:10px;">
													<div><img :src="items.logo" class="header-logo"
															style="margin-right:5px;"></div>
													<div>{{items.cname||items.name||items.usern}}</div>
													<div v-if="items.cphone||items.phone">
														（{{items.cphone||items.phone}}）</div>
													<div>

														<el-popconfirm confirm-button-text='确定' cancel-button-text='取消'
															icon="el-icon-info" icon-color="red" title="确定要解绑吗？"
															@confirm="unbind(items.id,iindex)">
															<img :src="path+'images/fb_icon8.png'" alt=""
																slot="reference" class="close"
																style="margin-left: 10px;;">

														</el-popconfirm>

													</div>
												</div>
											</div>
											</div>
										</el-descriptions-item>
									</template>
								</el-descriptions>
								<div style="border-bottom:1px dashed #ddd;margin-bottom:10px;"></div>
								<div class="fcj_ll">
									<div><el-button @click="zuofei" v-if="res.state==1" type="warning">合同作废</el-button></div>
									

								</div>
								<div v-show="isshowini">
									<div class="dotline"></div>
									<el-form :model="iniform" :rules="iniformrule" ref="iniform" label-width="160px">
										<div class="block">
											<!-- <el-form-item label="收款账号" prop="paycount">
												<el-select v-model="iniform.paycount" placeholder="请选择收款账号"
													style="width:300px;">
													<el-option v-for="(item,index) in banks" :label="item.des"
														:value="index" :key="index">
														{{item.des}}
													</el-option>
												</el-select>
											</el-form-item>
											-->
											<el-form-item label="最迟付款日期" prop="endtime">
												<el-input-number controls-position="right" :controls="false" :step="1"
													:min="0" v-model="iniform.endtime" style="width:120px;">
												</el-input-number>
												<span>（账单周期开始日期前N天）</span>
											</el-form-item>



											<div class="fcjtitle">对公开票信息</div>
											<el-form-item label="单位名称" prop="company">
												<el-input v-model="iniform.taxcompany" style="width:400px;"></el-input>
											</el-form-item>
											<el-form-item label="税号" prop="taxnumber">
												<el-input v-model="iniform.taxnumber" style="width:400px;"></el-input>
											</el-form-item>
											<el-form-item label="地址及电话" prop="taxaddress">
												<el-input v-model="iniform.taxaddress" style="width:400px;"></el-input>
											</el-form-item>
											<el-form-item label="开户行及账号" prop="bankinfo">
												<el-input v-model="iniform.taxbankinfo" style="width:400px;"></el-input>
											</el-form-item>
										</div>



										<el-form-item>
											<el-button type="primary" @click="inis()">确定</el-button>
											<el-button @click="backg">取消</el-button>
										</el-form-item>
									</el-form>
								</div>
							</div>

						</el-col>

					</el-row>

				</div>


			</el-col>
		</el-row>

		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">
					<div class="panel-header">
						<div class="charttitle">
							<div><img :src="path+'images/icon/history.png'" class="licon"></div>
							<div>合同操作记录</div>
						</div>
					</div>
					<div class="panel-body pad16">
						<div class="stepbody">
							<div v-for="(item,index) in log.res" :key="index" class="stepitem">
								<div class="steporder stepdian">
									<div class="stepc"></div>
									<div class="stepline"></div>
								</div>
								<div style="margin-top:-4px;">
									<div class="fcj_ll" style="padding:0px 10px 40px 0;font-size:14px;">
										<div style="color: #96A1B3;width:160px;">{{item.create_time}}</div>
										<div style="margin-right:20px ;">{{item.name||item.usern}}</div>
										<div>{{item.content}}</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</el-col>
		</el-row>

		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">
					
					<div class="panel-body pad16">
						<div class="fcj_ll" style="align-items: flex-start;font-size:12px;">
							<div style="padding-right:10px;min-width: 100px;">
								<div>合同审批</div>
								<div style="margin:10px 0px;"><el-button size="medium" @click="resend" plain v-if="res.state==1&&((!pstate[1])||pstate[1]!=1)">重新发起审批</el-button></div>
								<div style="margin:10px 0px;"><el-button  size="medium" type="warning" plain @click="revoke(1)" v-if="pstate[1]==1">撤销审批</el-button></div>
								<div class="stepbody" v-if="process_instance['1']">
									<div v-for="(item,index) in process_instance['1']" :key="index" class="stepitem">
										<div class="steporder stepdian">
											<div class="stepc"></div>
											<div class="stepline"></div>
										</div>
										<div style="margin-top:-4px;">
											<div style="padding:0px 10px 20px 0;font-size:14px; align-items: flex-start;">
												<div style="color: #96A1B3;width:160px;">{{item.date}}</div>
												<div class="fcj_ll" style="margin-top:5px;align-items: flex-start;">
													<div style="margin-right:20px ;">{{item.name||item.userid}}</div>
													<div><div><el-tag size="small" style="margin-bottom:10px;" type="success" v-if="item.operation_result=='AGREE'">同意</el-tag> <el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_result=='REFUSE'">拒绝</el-tag><el-tag size="small" style="margin-bottom:10px;" type="info" v-if="item.operation_type=='START_PROCESS_INSTANCE'">发起审批</el-tag><el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_type=='TERMINATE_PROCESS_INSTANCE'">撤销审批</el-tag></div><div>{{item.remark?item.remark:''}}</div>
														<div v-for="(iitem) in item.attachments" :key="'at'+iitem.file_id" @click="downddatt(iitem.file_id)">{{iitem.file_name}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>


							<div style="padding-right:10px;" v-if="ddbackmodel">
								<div>物业房间清退审批</div>
								<div style="margin:10px 0px;"><el-button plain size="medium" @click="backofficeshow" v-if="pstate[2]!=1">发起审批</el-button></div>
								<div style="margin:10px 0px;"><el-button  size="medium" type="warning" plain @click="revoke(2)" v-if="pstate[2]==1">撤销审批</el-button></div>

								<div class="stepbody" v-if="process_instance['2']">
									<div v-for="(item,index) in process_instance['2']" :key="index" class="stepitem">
										<div class="steporder stepdian">
											<div class="stepc"></div>
											<div class="stepline"></div>
										</div>
										<div style="margin-top:-4px;">
											<div style="padding:0px 10px 20px 0;font-size:14px; align-items: flex-start;">
												<div style="color: #96A1B3;width:160px;">{{item.date}}</div>
												<div class="fcj_ll" style="margin-top:5px;align-items: flex-start;">
													<div style="margin-right:20px ;">{{item.name||item.userid}}</div>
													<div><div><el-tag size="small" style="margin-bottom:10px;" type="success" v-if="item.operation_result=='AGREE'">同意</el-tag> <el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_result=='REFUSE'">拒绝</el-tag><el-tag size="small" style="margin-bottom:10px;" type="info" v-if="item.operation_type=='START_PROCESS_INSTANCE'">发起审批</el-tag><el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_type=='TERMINATE_PROCESS_INSTANCE'">撤销审批</el-tag></div><div>{{item.remark?item.remark:''}}</div>
														<div v-for="(iitem) in item.attachments" :key="'at'+iitem.file_id" @click="downddatt(iitem.file_id)">{{iitem.file_name}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div style="padding-right:10px;min-width: 100px;" v-if="ddbackmodel">
								<div>退房租单审批</div>
								
								<div style="margin:10px 0px;"><el-button size="medium" @click="newapproval(3)" v-if="pstate[3]!=1">发起审批</el-button></div>
								<div style="margin:10px 0px;"><el-button  size="medium" type="warning" plain @click="revoke(3)" v-if="pstate[3]==1">撤销审批</el-button></div>
								
								<div class="stepbody" v-if="process_instance['3']">
									<div v-for="(item,index) in process_instance['3']" :key="index" class="stepitem">
										<div class="steporder stepdian">
											<div class="stepc"></div>
											<div class="stepline"></div>
										</div>
										<div style="margin-top:-4px;">
											<div style="padding:0px 10px 20px 0;font-size:14px; align-items: flex-start;">
												<div style="color: #96A1B3;width:160px;">{{item.date}}</div>
												<div class="fcj_ll" style="margin-top:5px;align-items: flex-start;">
													<div style="margin-right:20px ;">{{item.name||item.userid}}</div>
													<div><div><el-tag size="small" style="margin-bottom:10px;" type="success" v-if="item.operation_result=='AGREE'">同意</el-tag> <el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_result=='REFUSE'">拒绝</el-tag><el-tag size="small" style="margin-bottom:10px;" type="info" v-if="item.operation_type=='START_PROCESS_INSTANCE'">发起审批</el-tag><el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_type=='TERMINATE_PROCESS_INSTANCE'">撤销审批</el-tag></div><div>{{item.remark?item.remark:''}}</div>
														<div v-for="(iitem) in item.attachments" :key="'at'+iitem.file_id" @click="downddatt(iitem.file_id)">{{iitem.file_name}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div style="padding-right:10px;min-width: 100px;" v-if="ddbackmodel">
								<div>退押金单审批</div>
								<div style="margin:10px 0px;"><el-button size="medium" @click="newapproval(4)" v-if="pstate[4]!=1">发起审批</el-button></div>
								<div style="margin:10px 0px;"><el-button  size="medium" type="warning" plain @click="revoke(4)" v-if="pstate[4]==1">撤销审批</el-button></div>
								
								<div class="stepbody" v-if="process_instance['4']">
									<div v-for="(item,index) in process_instance['4']" :key="index" class="stepitem">
										<div class="steporder stepdian">
											<div class="stepc"></div>
											<div class="stepline"></div>
										</div>
										<div style="margin-top:-4px;">
											<div style="padding:0px 10px 20px 0;font-size:14px; align-items: flex-start;">
												<div style="color: #96A1B3;width:160px;">{{item.date}}</div>
												<div class="fcj_ll" style="margin-top:5px;align-items: flex-start;">
													<div style="margin-right:20px ;">{{item.name||item.userid}}</div>
													<div><div><el-tag size="small" style="margin-bottom:10px;" type="success" v-if="item.operation_result=='AGREE'">同意</el-tag> <el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_result=='REFUSE'">拒绝</el-tag><el-tag size="small" style="margin-bottom:10px;" type="info" v-if="item.operation_type=='START_PROCESS_INSTANCE'">发起审批</el-tag><el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_type=='TERMINATE_PROCESS_INSTANCE'">撤销审批</el-tag></div><div>{{item.remark?item.remark:''}}</div>
														<div v-for="(iitem) in item.attachments" :key="'at'+iitem.file_id" @click="downddatt(iitem.file_id)">{{iitem.file_name}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div style="padding-right:10px;min-width: 100px;" v-if="ddbackmodel">
								<div>其他合同流转审批</div>
								<div style="margin:10px 0px;"><el-button size="medium" @click="newapproval(5)" v-if="pstate[5]!=1">发起审批</el-button></div>
								<div style="margin:10px 0px;"><el-button  size="medium" type="warning" plain @click="revoke(5)" v-if="pstate[5]==1">撤销审批</el-button></div>
								
								<div class="stepbody" v-if="process_instance['5']">
									<div v-for="(item,index) in process_instance['5']" :key="index" class="stepitem">
										<div class="steporder stepdian">
											<div class="stepc"></div>
											<div class="stepline"></div>
										</div>
										<div style="margin-top:-4px;">
											<div style="padding:0px 10px 20px 0;font-size:14px; align-items: flex-start;">
												<div style="color: #96A1B3;width:160px;">{{item.date}}</div>
												<div class="fcj_ll" style="margin-top:5px;align-items: flex-start;">
													<div style="margin-right:20px ;">{{item.name||item.userid}}</div>
													<div><div><el-tag size="small" style="margin-bottom:10px;" type="success" v-if="item.operation_result=='AGREE'">同意</el-tag> <el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_result=='REFUSE'">拒绝</el-tag><el-tag size="small" style="margin-bottom:10px;" type="info" v-if="item.operation_type=='START_PROCESS_INSTANCE'">发起审批</el-tag><el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_type=='TERMINATE_PROCESS_INSTANCE'">撤销审批</el-tag></div><div>{{item.remark?item.remark:''}}</div>
														<div v-for="(iitem) in item.attachments" :key="'at'+iitem.file_id" @click="downddatt(iitem.file_id)">{{iitem.file_name}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div style="padding-right:10px;min-width: 100px;" v-if="ddbackmodel">
								<div>主体变更</div>
								<div style="margin:10px 0px;"><el-button size="medium" @click="newapproval(6)" v-if="pstate[6]!=1">发起审批</el-button></div>
								<div style="margin:10px 0px;"><el-button  size="medium" type="warning" plain @click="revoke(6)" v-if="pstate[6]==1">撤销审批</el-button></div>
								
								<div class="stepbody" v-if="process_instance['6']">
									<div v-for="(item,index) in process_instance['6']" :key="index" class="stepitem">
										<div class="steporder stepdian">
											<div class="stepc"></div>
											<div class="stepline"></div>
										</div>
										<div style="margin-top:-4px;">
											<div style="padding:0px 10px 20px 0;font-size:14px; align-items: flex-start;">
												<div style="color: #96A1B3;width:160px;">{{item.date}}</div>
												<div class="fcj_ll" style="margin-top:5px;align-items: flex-start;">
													<div style="margin-right:20px ;">{{item.name||item.userid}}</div>
													<div><div><el-tag size="small" style="margin-bottom:10px;" type="success" v-if="item.operation_result=='AGREE'">同意</el-tag> <el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_result=='REFUSE'">拒绝</el-tag><el-tag size="small" style="margin-bottom:10px;" type="info" v-if="item.operation_type=='START_PROCESS_INSTANCE'">发起审批</el-tag><el-tag size="small" style="margin-bottom:10px;" type="danger" v-if="item.operation_type=='TERMINATE_PROCESS_INSTANCE'">撤销审批</el-tag></div><div>{{item.remark?item.remark:''}}</div>
														<div v-for="(iitem) in item.attachments" :key="'at'+iitem.file_id" @click="downddatt(iitem.file_id)">{{iitem.file_name}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>


						</div>
						

					</div>
				</div>
			</el-col>
		</el-row>

		<el-dialog :visible.sync="isshowcustomer" width="500" :close-on-click-modal='false'>
			<div class="fcj_ll">
				<div style="flex:1;">
					<el-input v-model="inputkey" placeholder="请输入公司名称"></el-input>
				</div>
				<div>
					<el-button @click="addmember(1)" type="primary" style="margin-left:5px;">查找</el-button>
				</div>
			</div>
			<div style="padding:20px;max-height: 300px;overflow: auto;">
				<div v-for="(item,index) in comlist" :key="'a'+index" style="cursor: pointer;" @click="showu(item.id)"
					v-show="item.id!=id">
					{{item.id+'，'+item.number+'，'+item.company}}
				</div>
			</div>
			<div style="padding:20px;max-height: 300px;overflow: auto;">
				<div>
					<div v-for="(item,index) in ulist" :key="'u'+index" style="cursor: pointer;">
						{{item.cname+' '+item.phone}}
					</div>
				</div>
				<div v-if="ulist.length==0&&ulist!==''">
					没有绑定人员
				</div>

				<div v-if="ulist.length>0">
					<el-button @click="addu" type="primary" style="margin-top:10px;">添加</el-button>
				</div>
			</div>

		</el-dialog>

		<el-dialog :visible.sync="isshowddform" width="600px"  :close-on-click-modal="false">
			<form-create v-model="fApi" :rule="ddrule" :option="option" :value.sync="ddformdata" :label-width="90">
			</form-create>
		</el-dialog>

		<el-dialog :visible.sync="isshowdduser" append-to-body>
			<div class="fcj_lc" style="align-items: flex-start;">
				<div class="utt">
					<div>部门列表</div>
					<div v-for="(item) in dddpt" :key="item.dptid" @click="dduser(item.dept_id)" style="cursor: pointer;">
						{{item.name}}
					</div>
				</div>
				<div class="utt">
					<div>人员列表</div>
					<div v-for="(item,index) in dduserlist" :key="item.unionid" @click="chdduser(index)" style="cursor: pointer;">
						{{item.name}}
					</div>
				</div>
				<div class="utt">
					<div>已选人员</div>
					<div v-if="ddusertype==0">
						<div v-for="(item,index) in sedduser" :key="item.userid" @click="chdduser(item.userid,index)" style="cursor: pointer;">
							{{item.name}}
						</div>
					</div>
					<div v-if="ddusertype==1">
						<div v-for="(item,index) in seddcc" :key="item.userid" @click="chdduser(item.userid,index)" style="cursor: pointer;">
							{{item.name}}
						</div>
					</div>
				</div>
			</div>

		</el-dialog>
		<el-dialog :visible.sync="showoffice" append-to-body width="600px">
			<div style="text-align:center;width:600px;">
				<div style="margin-bottom:10px;">选择要退的房间</div>
				<el-select v-model="seBackOffice" multiple placeholder="请选择" style="width:400px;">
					<el-option
					v-for="(item,index) in office"
					:key="item.id"
					:label="item.roomnumber"
					:value="index">
					</el-option>
				</el-select>
				<div>
					<el-button @click="newapproval(2)" type="primary" style="margin-top:20px;">提交退房申请</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data(vm) {
			return {
				seBackOffice:[],
				showoffice:false,
				ddbackmodel:false,
				acsys:{},
				uploaddata:{
					pusern:localStorage.getItem('pusern'),
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				approval_state: {'-1':'审批已撤销','0':'无需审批', '1':'审批中', '2':'不通过', '3':'通过'},
				acontractid: 0,
				ulist: '',
				comlist: [],
				inputkey: '',
				isshowcustomer: false,
				path: this.$host,
				breadcrumb: [],
				abc: false,
				isshowini: false,
				id: '',
				res: {},
				qrcode: '',
				log: {},
				p: '',
				states: [],
				state: '',
				banks: '',
				office: '',
				phone: '',
				personal: '',
				ofindex: 0,
				dizeng: [],
				contracttype: {},
				paytype: {},
				iniform: {
					do: 'ini',
					id: '',
					paycount: '',
					endtime: 5,
					taxcompany: '',
					taxnumber: '',
					taxaddress: '',
					taxbankinfo: ''
				},
				iniformrule: {
					paycount: [{
						required: true,
						message: '请选择收款账号',
						trigger: 'change'
					}],
					endtime: [{
						required: true,
						message: '请输入最迟付款日期',
						trigger: 'blur'
					}],
				},
				descdate: [],
				process_instance: [],


				//钉钉审批参数
				dddpt:[],
				dduserlist:[],
				sedduser:[],
				loading:false,
				dingding:'',
				sedduserdiv:[],
				seddccdiv:[],
				seddcc:[],//钉钉抄送人列表
				ddusertype:0,//当前是选审批人还是抄送人1，审批人，1抄送人
				ddformattdata:[],//钉钉表单附件暂存
				ddformattdataview:[],
				schema:[],
				//实例对象
				fApi: {},
				//表单数据
				ddformdata: {},
				//表单生成规则
				ddrule: [],
				//组件参数配置
				option: {
					
					//表单提交事件
					onSubmit: function (formData) {
						vm.addddform();
					}
				},
				ddpost:{},
				isshowdduser:false,
				isshowddform:false,
				approval_tpid:1,
				modeid:'',
				pstate:[],
				rent_type:[],
				createtype:[]
			}
		},
		watch: {
			$route: function (to, from) {
				this.id = this.$route.query.id;
				this.getdata();
				this.getshenpi();
			},
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.$root.myrouter = vm.$breadcrumb(vm.$root.myrouter, to)
			})
		},
		mounted: function() {
			this.breadcrumb = this.$root.myrouter
			this.id = this.$route.query.id;
			this.host = this.$host
			this.getdata();
			this.getshenpi();
		},
		methods: {
			//下载钉钉附件
			downddatt(v){
				this.$ajax({
					url: 'ddapi/',
					data: {
						'do': 'downatt',
						'id': v,
					},
				}).then(process => {
					console.log(process);
				});
			},
			//撤销审批
			revoke(v){
				this.$confirm("确认要撤销吗？","提示").then(()=>{
					this.$ajax({
						url: 'ddapi/',
						data: {
							'do': 'revoke',
							'id': this.id,
							'tp':v
						},
					}).then(res => {
						if(res.error==0){

							this.getshenpi()
						}
						this.$alert(res.msg);
					});
				}).catch(()=>{})
			},
			backofficeshow(){
				this.showoffice=true;
			},
			//发起审批,初始化审批表单
			newapproval(v){

				if(v==2){//物业退租审批
					if(this.seBackOffice.length==0){
						this.$alert('请选择要退租的房间');
						return;
					}
					this.showoffice=false;
				}else if(v==3){//退房租单
				}else if(v==4){//退押金单
				}else if(v==5){//其他合同流转
				}else if(v==6){//主体变更
				}

				this.approval_tpid=v;
				this.ddrule=[];
				const that=this;
				this.$ajax({
					url: 'ddapi/',
					data: {
						do: 'getschema',
						id:v
					},
				}).then(res => {
					var j=0;
					var tmp=res.result.schemaContent.items;
					this.modeid=res.result.formCode;
					this.schema=tmp;
					if(localStorage.getItem('sedduser')){
						this.sedduser=JSON.parse(localStorage.getItem('sedduser'));
						this.sedduserdiv=JSON.parse(localStorage.getItem('sedduserdiv'));
					}
					if(localStorage.getItem('seddcc')){
						this.seddcc=JSON.parse(localStorage.getItem('seddcc'));
						this.seddccdiv=JSON.parse(localStorage.getItem('seddccdiv'));
					}
					for (var i = tmp.length - 1; i >= 0; i--) {
						if('TextNote'==tmp[i].componentName){
							this.schema.splice(i,1);
							continue;
						}
						if(tmp[i].componentName=="TextField"||"NumberField"==tmp[i].componentName||'MoneyField'==tmp[i].componentName){
							this.ddrule.unshift({
								type: 'input',
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								wrap:{labelWidth:'145px'},
								props:{
									placeholder:tmp[i].props.placeholder,
								},
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}]
							})
						}else if("DDMultiSelectField"==tmp[i].componentName){
							for(j=0;j<tmp[i].props.options.length;j++){
								tmp[i].props.options[j]=JSON.parse(tmp[i].props.options[j]);
								tmp[i].props.options[j].label=tmp[i].props.options[j].value;

							}
							this.ddrule.unshift({
								type: "select",
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								options: tmp[i].props.options,
								wrap:{labelWidth:'145px'},
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									multiple: false
								}
							})
						}else if("DDSelectField"==tmp[i].componentName){
							for(j=0;j<tmp[i].props.options.length;j++){
								tmp[i].props.options[j]=JSON.parse(tmp[i].props.options[j]);
								tmp[i].props.options[j].label=tmp[i].props.options[j].value;

							}
							this.ddrule.unshift({
								type: "select",
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								options: tmp[i].props.options,
								wrap:{labelWidth:'145px'},
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									multiple: false
								}
							})
						}else if("DDDateField"==tmp[i].componentName){
							
							this.ddrule.unshift({
								type: "DatePicker",
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								wrap:{labelWidth:'145px'},
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									format: tmp[i].props.format,
								}
							})
						}else if("TextareaField"==tmp[i].componentName){
							this.ddrule.unshift({
								type: 'input',
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								wrap:{labelWidth:'145px'},
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									rows:5,
									type: "textarea",
								},
							})
						}else if("DDAttachment"==tmp[i].componentName||"DDPhotoField"==tmp[i].componentName){
							var updata=JSON.parse(JSON.stringify(this.uploaddata));
							updata['noc']=tmp[i].props.id;

							this.ddrule.unshift({
								type: 'el-upload',
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								wrap:{labelWidth:'145px'},
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									action:this.host+'upfile/',
									name:"upfile",
									data: updata,
									onSuccess:function (res, file ,filelist) {
										console.log(file);
										console.log(filelist);
										console.log(that.value);
										that.ddformdata[res.noc]=[]
										for(var i=0;i<filelist.length;i++){
											that.ddformdata[res.noc].push(filelist[i].response.fileid)
										}
										//file.url=res.fileid
										//that.ddformattdata.push(file);
									}
								},
								children:[{
									type: 'el-button',
									children:['上传附件']
								}]
							})

						}
						
					}
					this.ddrule.push({
						type:'div',
						props:{
							class:'el-col el-col-24 fcj_ll',
							style:'padding-bottom:13px;'
						},
						children:[{
							type:'div',
							props:{
								style:'width:145px;'
							}
						},{
							type:'div',
							children:[
								{
									type:'el-button',
									children:['选择审批人'],
									on:{
										click:()=>{
											this.ddsp(0);
										}
									}
								},
								{
									type:'div',
									style:'padding:10px 0',
									children:this.sedduserdiv
								}
							]
						}],
						
					})
					this.ddrule.push({
						type:'div',
						props:{
							class:'el-col el-col-24 fcj_ll',
							style:'padding-bottom:13px;'
						},
						children:[{
							type:'div',
							props:{
								style:'width:145px;'
							}
						},{
							type:'div',
							children:[
								{
									type:'el-button',
									children:['选择抄送人'],
									on:{
										click:()=>{
											this.ddsp(1);
										}
									}
								},
								{
									type:'div',
									style:'padding:10px 0',
									children:this.seddccdiv
								}
							]
						}],
						
					})
					this.isshowddform=true;


					for (i = 0 ;i<this.ddrule.length ; i++) {
						if(this.ddrule[i].title=='合同名称'||'变更前的名称'==this.ddrule[i].title){
							this.ddrule[i].value=this.res.company
							continue;
						}
						if(this.ddrule[i].title=='室号名称'||this.ddrule[i].title=='室号'){
							this.ddrule[i].value=this.res.office
							continue;
						}
						if(this.ddrule[i].title=='房间面积'||'退租面积（平方米）'==this.ddrule[i].title){
							this.ddrule[i].value=this.res.area/100
							continue;
						}
						if(this.ddrule[i].title=='客户名称'){
							this.ddrule[i].value=this.res.company
							continue;
						}
						if(this.ddrule[i].title=='租赁开始日'){
							this.ddrule[i].value=this.res.regist_time
							continue;
						}
						
					}

				});
			},
			//添加，删除审批人
			chdduser(v,s){	
				var uname='';
				if(this.ddusertype==0){
					uname='sedduser';
				}else{
					uname='seddcc';
				}
				if(s!=undefined){
					
					this[uname].splice(s,1);
					this[uname+'div'].splice(s,1);
				}else{
					for(var i=0;i<this[uname].length;i++){
						if(this[uname][i].userid==this.dduserlist[v].userid){
							break;
						}
					}
					if(i==this[uname].length){
						this[uname+'div'].push({type:'el-tag',style:'margin-right:5px',children:[this.dduserlist[v].name]})
						this[uname].push(this.dduserlist[v]);
					}else{
						this.$alert('已选');
					}
				}
				localStorage.setItem('sedduser',JSON.stringify(this.sedduser))
				localStorage.setItem('sedduserdiv',JSON.stringify(this.sedduserdiv))
				localStorage.setItem('seddcc',JSON.stringify(this.seddcc))
				localStorage.setItem('seddccdiv',JSON.stringify(this.seddccdiv))
			},
			//钉钉用户列表
			dduser(dptid){
				const that = this;
				this.$ajax({
					url: 'ddapi/',
					data: {
						do: 'dingdinguser',
						'dpid':dptid,
						'all':1
					},
				}).then(res => {
					if(res.errcode==0){
						that.dduserlist=res.result.list
					}else{
						this.$alert(res.errmsg);
					}
				});
			},
			//部门列表
			ddsp(v){
				this.dduserlist=[];
				this.ddusertype=v;
				const that = this;
				this.formcheckres=0;
				this.isshowdduser=true;
				this.$ajax({
					url: 'ddapi/',
					data: {
						do: 'dingdinguser'
					},
				}).then(res => {
					if(res.errcode==0){
						that.dddpt=res.result
					}else{
						this.$alert(res.errmsg);
					}
				});
				
			},
			//提交钉钉审批
			ddadd(){
				this.loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				this.ddpost.do='add'
				this.ddpost.contractid=this.id;
				this.ddpost.approval_tpid=this.approval_tpid
				this.ddpost.modeid=this.modeid
				if(this.approval_tpid==2){//退房审批
					var tmpofficeid=[];
					for(var i=0;i<this.seBackOffice.length;i++){
						tmpofficeid.push(this.office[this.seBackOffice[i]].id)
					}
					this.ddpost.backoffice=tmpofficeid;
				}
				
				//提交钉钉审批
				this.$ajax({
					url: 'ddapi/',
					data: this.ddpost
				}).then(res => {
					this.dingding='';
					this.loading.close();
					if(res.error==0){
						this.$message({
							message: res.msg,
							type: 'success'
						})
						this.$router.go(-1)
					}else{
						this.$alert("提交钉钉审批失败\r\n"+(res.data.errmsg||res.msg))
					}
					
					this.loading.close();
					

				});
			},
			//审批结果详情
			getshenpi(){

				this.$ajax({
					url: 'ddapi/',
					data: {
						'do': 'approvalinfo',
						'id': this.id
					},
				}).then(process => {
					this.process_instance=[];
					this.pstate=[];
					for (var key in process) {
					
						var tmp=[];
						for( var i=0;i<process[key].length;i++){
							for(var j=0;j<process[key][i].process_instance.operation_records.length;j++){
								tmp.push(process[key][i].process_instance.operation_records[j]);
							}
						}
						this.pstate[key]=process[key][process[key].length-1].state;
						this.process_instance[key] = tmp;
					
						
					}
				});
			},

			addddform(){
				if(this.sedduser.length==0){
					this.$message({
						message: '请选择审批人',
						type: 'warning'
					})
					return
				}else{
					var uu=[]
					for(var i=0;i<this.sedduser.length;i++){
						uu.push(this.sedduser[i].userid);
					}
				}
				var cc=[];
				if(this.seddcc.length>0){
					for(i=0;i<this.seddcc.length;i++){
						cc.push(this.seddcc[i].userid);
					}
				}
				this.ddpost.dduser=uu;
				this.ddpost.cc=cc;
				// for(var n in this.ddformattdata){
				// 	this.ddformdata[this.ddformattdata[n].response.noc].push(this.ddformattdata[n].response.fileid);
				// }
				var fd=[];
				for(i=0;i<this.schema.length;i++){
					fd[i]={"name":this.schema[i].props.label,"value":this.ddformdata[this.schema[i].props.id]?this.ddformdata[this.schema[i].props.id]:'',"tp":this.schema[i].componentName}
				}
				this.ddpost.formdata=fd;

				this.ddadd()
			},

			uploadsuccess(res){
				this.$ajax({
					url: 'contract/',
					data: {
						'do': 'updateattachment',
						'contractid':this.id,
						'fileid': res.fileid
					},
				}).then(process => {
					this.$refs.uploadparkpic.clearFiles();
					this.getdata();
				});
			},
			resend(){
				this.$router.push('contractform?id='+this.id);
			},
			zuofei() {
				this.$confirm('作废后不可恢复，确定要作废吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$ajax({
						url: 'contract/',
						data: {
							'do': 'update',
							'id': this.id,
							'st': 2
						},
					}).then(res => {

						if (res.error == 0) {
							this.res.state = 2
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});

						} else {
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});

						}
					})
				}).catch(() => {});
			},
			backg() {
				this.$router.go(-1)
			},
			unbind(v, id) {
				this.$ajax({
					url: 'contract/',
					data: {
						'do': 'unbind',
						'mid': v
					},
				}).then(res => {
					this.p.res.splice(id, 1);
				})
			},
			showu(v) {
				this.$ajax({
					url: 'bind/',
					data: {
						'do': 'search',
						'id': v
					},
				}).then(res => {
					if (res.data.c > 0) {
						this.acontractid = v;
						this.ulist = res.data.res;
					} else {
						this.ulist = []
					}
				})
			},
			addu() {
				this.$ajax({
					url: 'bind/',
					data: {
						'do': 'search',
						'cid': this.acontractid,
						'ncid': this.id
					},
				}).then(res => {
					this.getdata();
					this.isshowcustomer = false;
					this.$alert('添加成功')
				})
			},
			addmember(v) {
				if (v == 1) {

					this.$ajax({
						url: 'bind/',
						data: {
							'do': 'search',
							'key': this.inputkey
						},
					}).then(res => {
						if (res.data.c > 0) {
							this.comlist = res.data.res;
						}
					});
				} else {
					this.ulist = '';
					this.comlist = [];
					this.isshowcustomer = true;
				}
			},
			inis() {
				this.$confirm('账单初始化后合同正式生效不可更改，是否继续？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.iniform.id = this.res.id;

					this.$ajax({
						url: 'contract/',
						data: this.iniform,
					}).then(res => {
						const that = this;
						if (res.error == 0) {
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							}).then(() => {
								that.$router.go(-1)
							})
						} else {

							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							})
						}

					})
				}).catch(() => {

				});

			},
			
			getdata() {
				this.$ajax({
					url: 'contract/',
					data: {
						'do': 'info',
						'id': this.id,
					},
				}).then(res => {
					this.acsys=res.acsys;
					this.acsys.models=this.acsys.models.split(',');
					if(this.acsys.models.indexOf('25')>=0){
						this.ddbackmodel=true;
					}else{
						this.ddbackmodel=false;
					}
					this.res = res.res[0]
					if (this.res.firstdate != '') {
						this.res.firstdate = this.res.firstdate.join(' - ')
					}
					if (this.res.firstpropertydate != '') {
						this.res.firstpropertydate = this.res.firstpropertydate.join(' - ')
					}

					this.iniform.taxcompany = res.customer.taxcompany
					this.iniform.taxnumber = res.customer.taxnumber
					this.iniform.taxaddress = res.customer.taxaddress
					this.iniform.taxbankinfo = res.customer.taxbankinfo
					this.iniform.paycount = parseInt(this.res.bankid)

					var n = new Date(this.res.regist_time);
					n = n - 1000 * 3600 * 24 * 5;
					n = new Date(n);
					this.iniform.endtime = n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate()

					this.contracttype = res.contracttype
					this.paytype = res.paytype
					this.rent_type = res.rent_type
					this.createtype = res.createtype
					this.p = res.p
					this.log = res.log
					this.states = res.state
					this.banks = res.banks
					this.back = res.return ? res.return : {}
					this.dizeng = JSON.parse(res.res[0].dizeng)
					for (var i = 0; i < this.dizeng.length; i++) {
						this.dizeng[i].month = (this.dizeng[i].deposit * res.res[0].area * 365 / 12 / 100).toFixed(
							2);
					}
					this.qrcode = res.qrcode
					this.office = res.office
					this.phone = res.res[0].phone
					this.personal = res.res[0].connecter
					this.res.avgstar = parseFloat(this.res.avgstar);
					if (this.res.state == 1 && this.$route.query.ini == 1) {
						this.isshowini = true;
					}
					if (res.sup.c > 0) {
						this.descdate = res.sup.res
					}

					
				})
			}
		},
	}
</script>

